<template>
  <div>
    <!-- uploadC9A809AE-5B68-4F14-9DF8-147533D9D03B.swf -->
    <!-- pic5D73F7804EB9D2A6FB180C256C19AC95.jpg -->
    <img class="img-large rounded" src="@/assets/news-network/challenge-city.jpg">
    <p>
      A new challenge is live in the <router-link :to="{ name: 'creation-lab-list', params: {challengeId: 'C28C0CFF-39DD-4D5C-9B3C-5496E3C8ED03'} }">LEGO Universe Creation Lab</router-link>! Let the dust fly as you take out your tractors and crank up your cranes! Start stacking bricks and building skyscrapers! Can you create a city?
    </p>
  </div>
</template>
